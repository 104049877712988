<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="filterRequests"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
            @click:row="openRequest"
        >
            <template v-slot:top>
                <div class="header-container pt-3 px-1">
                    <v-container fluid>
                        <v-row justify="end">
                            <v-col cols="auto">
                                <v-btn
                                    @click="openImportPurchase"
                                    color="primary"
                                    class="d-flex align-center"
                                    :disabled="loading"
                                >
                                    IMPORT
                                    <v-icon class="ml-1">mdi-import</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
            </template>

            <!--HEADERS-->
            <template v-slot:[`header.number`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="idToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <template v-slot:[`header.order`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="orderToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <template v-slot:[`header.project`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="projectToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <template v-slot:[`header.deliveryLocation`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="locationToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <template v-slot:[`header.date`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="dateToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <template v-slot:[`header.dueDate`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="dueDateToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <template v-slot:[`header.items`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="itemsToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <template v-slot:[`header.notes`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="notesToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <template v-slot:[`header.createdBy`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="usersToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <template v-slot:[`header.status`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="statusToFilter"
                    dense
                    class="pt-2"
                />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.status`]="{ item }">
                <div class="d-flex justify-center">
                    <v-chip :color="statusColors[item.status].color" dark>
                        <span
                            class="mt-0 text-body-1 text-capitalize"
                            :style="{ 'text-align': 'center !important' }"
                            >{{ statusColors[item.status].text }}</span
                        >
                    </v-chip>
                </div>
            </template>
            <template v-slot:[`item.date`]="{ item }">
                <p class="my-0">
                    {{ formatDate(item.createdOn.seconds) }}
                </p>
            </template>
            <template v-slot:[`item.createdBy`]="{ item }">
                <div class="my-0">
                    <div>
                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <p
                                    class="my-0 font-weight-medium"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    {{ item.createdBy?.name }}
                                </p>
                            </template>
                            <span>Creator</span>
                        </v-tooltip>
                    </div>
                    <div v-if="item.projectManager">
                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <p
                                    class="my-0 font-weight-medium"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    {{ item.projectManager.name }}
                                </p>
                            </template>
                            <span>Project Manager</span>
                        </v-tooltip>
                    </div>
                    <div v-if="item.buyer">
                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <p
                                    class="my-0 font-weight-medium"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    {{ getUser(item.buyer) }}
                                </p>
                            </template>
                            <span>Buyer</span>
                        </v-tooltip>
                    </div>
                </div>
            </template>
            <template v-slot:[`item.totalPrice`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">{{ formatNumber(calculateTotal(item)) }}</p>
                </div>
            </template>
            <template v-slot:[`item.project`]="{ item }">
                <p class="my-0 text-capitalize">{{ item.project.name }}</p>
            </template>
            <template v-slot:[`item.items`]="{ item }">
                <div>
                    <p
                        class="my-0"
                        v-for="(element, index) of item.items"
                        :key="index"
                    >
                        {{ element.description }} ({{ element.quantity }})
                        {{
                            element.purchaseOrder
                                ? '(' + element.purchaseOrder.orderNumber + ')'
                                : ''
                        }}
                    </p>
                </div>
            </template>
        </v-data-table>
        <!--ALERT MESSAGES-->
        <v-alert
            v-if="activateAlert"
            type="success"
            color="primary"
            :style="{ position: 'absolute', right: '0px', bottom: '0px' }"
        >
            <v-row no-gutters>
                <v-col cols="11">
                    <p class="my-0">{{ alertMessage }}</p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="activateAlert = false"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <!--Comex Form-->
        <v-dialog persistent fullscreen v-model="openForm">
            <ComexForm
                v-if="openForm"
                :purchase="selectedRequest"
                @finalizeCOMEX="deleteRequest"
                @close="close"
            />
        </v-dialog>
        <!-- Import Purchase dialog -->
        <v-dialog
            :retain-focus="false"
            :loading="loading"
            persistent
            max-width="1350px"
            v-model="importPurchaseDialog"
        >
            <v-card>
                <v-card-title class="text-h5">
                    <v-btn
                        small
                        icon
                        @click="closeImportPurchase"
                        color="primary"
                        class="mr-2"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                    <h3>{{ 'Import Closed Purchase' }}</h3>
                </v-card-title>
                <v-divider class="ml-4 mr-5" />
                <v-card-text>
                    <p class="mt-4">Select the closed purchase to import.</p>
                    <v-autocomplete
                        prepend-icon="mdi-cart-outline"
                        label="Closed Purchase"
                        v-model="selectedClosedPurchase"
                        :items="closedPurchases"
                        item-text="displayText"
                        return-object
                    />
                    <div v-if="selectedClosedPurchase">
                        <v-data-table
                            :items="selectedClosedPurchase.items"
                            :headers="closedPurchaseItemsHeaders"
                            disable-pagination
                            hide-default-footer
                        >
                            <template v-slot:top>
                                <v-row
                                    no-gutter
                                    class="pa-0 ma-0"
                                    :style="{ 'background-color': '#eeeeee' }"
                                >
                                    <v-col cols="12" class="d-flex">
                                        <p
                                            class="text-h5 my-0 pl-3 font-weight-bold"
                                        >
                                            Received Items
                                        </p>
                                    </v-col>
                                </v-row>
                            </template>
                            <!--ITEMS-->
                            <template v-slot:[`item.index`]="{ index }">
                                <p class="mt-3 font-weight-medium">
                                    {{ index + 1 }}
                                </p>
                            </template>
                            <template v-slot:[`item.code`]="{ item }">
                                <p class="mt-3 font-weight-medium">
                                    {{ item.code }}
                                </p>
                            </template>
                            <template v-slot:[`item.description`]="{ item }">
                                <p class="mt-3 font-weight-medium">
                                    {{ item.description }}
                                </p>
                            </template>
                            <template v-slot:[`item.quantity`]="{ item }">
                                <p class="mt-3 font-weight-medium">
                                    {{ item.quantity }}
                                </p>
                            </template>
                            <template v-slot:[`item.received`]="{ item }">
                                <p :class="validateItemQty(item)">
                                    {{ getReceived()(item.code) }}
                                </p>
                            </template>
                            <template v-slot:[`item.supplier`]="{ item }">
                                <p
                                    class="my-0 text-capitalize font-weight-medium"
                                >
                                    {{ getSupplier(item) }}
                                </p>
                            </template>
                            <template v-slot:[`item.order`]="{ item }">
                                <a
                                    v-if="
                                        item.purchaseOrder &&
                                            item.purchaseOrder.file
                                    "
                                    @click="handleOpenFile(item)"
                                    >{{ item.purchaseOrder.orderNumber }}</a
                                >
                                <p v-else class="font-weight-medium my-0">
                                    {{
                                        item.purchaseOrder
                                            ? item.purchaseOrder.orderNumber
                                            : ''
                                    }}
                                </p>
                            </template>
                            <template v-slot:[`item.price`]="{ item }">
                                <p
                                    class="font-weight-medium mt-3"
                                    v-if="item.quotes"
                                >
                                    {{
                                        item.quotes.find(quote => quote.able)
                                            ? numberFormat(
                                                  item.quotes.find(
                                                      quote => quote.able
                                                  ).price
                                              )
                                            : ''
                                    }}
                                </p>
                            </template>
                            <template v-slot:[`item.totalPrice`]="{ item }">
                                <p
                                    class="font-weight-medium mt-3"
                                    v-if="item.quotes"
                                >
                                    {{
                                        item.quotes.find(quote => quote.able)
                                            ? numberFormat(
                                                  item.quotes.find(
                                                      quote => quote.able
                                                  ).price * item.quantity
                                              )
                                            : ''
                                    }}
                                </p>
                            </template>
                            <template v-slot:[`item.import`]="{ item }">
                                <div class="d-flex justify-center">
                                    <v-checkbox v-model="item.isImportation">
                                    </v-checkbox>
                                </div>
                            </template>
                            <!-- FOOTER -->
                            <template v-slot:[`body.append`]>
                                <tr :style="{ 'background-color': '#eeeeee' }">
                                    <td colspan="7"></td>
                                    <td class="font-weight-bold">
                                        <p
                                            class="d-flex justify-center align-right my-0"
                                        >
                                            TOTAL:
                                        </p>
                                    </td>
                                    <td class="font-weight-bold">
                                        <p
                                            class="d-flex justify-center align-right my-0"
                                        >
                                            {{
                                                numberFormat(
                                                    selectedClosedPurchase.totalPrice ||
                                                        0
                                                )
                                            }}
                                        </p>
                                    </td>
                                    <td></td>
                                </tr>
                            </template>
                        </v-data-table>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="primary mt-n3 mb-2 mr-2"
                        @click="saveImport"
                        rounded
                        :disabled="itemsSelected < 1"
                    >
                        SAVE
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import ComexForm from '@/components/Comex/ComexForm.vue'
import { firestore } from '@/services/firebase'
import moment from 'moment'
import { mapMutations, mapGetters, mapState, mapActions } from 'vuex'
import API from '@/services/api'

export default {
    name: 'ComexMainBoard',
    components: {
        ComexForm,
    },
    data: () => ({
        createForm: false,
        selectedRequest: {},
        originalRequest: {},
        openForm: false,
        activateAlert: false,
        alertMessage: '',
        loading: false,
        headers: [
            {
                text: 'ID',
                value: 'number',
                sortable: false,
                align: 'center',
                width: '100px',
            },
            {
                text: 'PROJECT',
                value: 'project',
                sortable: false,
                align: 'left',
            },
            {
                text: 'ITEMS',
                value: 'items',
                sortable: false,
                align: 'left',
            },
            {
                text: 'TOTAL PRICE',
                value: 'totalPrice',
                sortable: false,
                align: 'center',
            },
            {
                text: 'USERS',
                value: 'createdBy',
                sortable: false,
                align: 'center',
            },
            {
                text: 'REQ DATE',
                value: 'date',
                sortable: false,
                align: 'center',
            },
            {
                text: 'DUE DATE',
                value: 'dueDate',
                sortable: false,
                align: 'center',
            },
            {
                text: 'STATUS',
                value: 'status',
                sortable: false,
                align: 'center',
                width: '100px',
            },
            {
                text: 'DELIVERY AT',
                value: 'deliveryLocation',
                sortable: false,
                align: 'center',
            },
            {
                text: 'NOTES',
                value: 'notes',
                sortable: false,
                align: 'left',
            },
        ],
        closedPurchaseItemsHeaders: [
            {
                text: 'INDEX',
                value: 'index',
                align: 'center',
                sortable: false,
                width: '95',
            },
            {
                text: 'CODE',
                value: 'code',
                align: 'center',
                sortable: false,
                width: '120',
            },
            {
                text: 'ORDER',
                value: 'order',
                align: 'center',
                sortable: false,
                width: '120',
            },
            {
                text: 'ITEM',
                value: 'description',
                align: 'left',
                sortable: false,
            },
            {
                text: 'SUPPLIER',
                value: 'supplier',
                align: 'left',
                sortable: false,
            },
            {
                text: 'PURCHASED',
                value: 'quantity',
                align: 'center',
                sortable: false,
                width: '95',
            },
            {
                text: 'RECEIVED',
                value: 'received',
                align: 'center',
                sortable: false,
                width: '95',
            },
            {
                text: 'PRICE',
                value: 'price',
                align: 'center',
                sortable: false,
            },
            {
                text: 'TOTAL',
                value: 'totalPrice',
                align: 'center',
                sortable: false,
            },
            {
                text: 'IMPORT',
                value: 'import',
                align: 'center',
                sortable: false,
            },
        ],
        requests: [],
        projectToFilter: undefined,
        deliveryParamToFilter: undefined,
        orderToFilter: undefined,
        dateToFilter: undefined,
        dueDateToFilter: undefined,
        notesToFilter: undefined,
        usersToFilter: undefined,
        statusToFilter: undefined,
        locationToFilter: undefined,
        companyId: JSON.parse(localStorage.getItem('company')),
        listener: null,
        idToFilter: undefined,
        resourceId: undefined,
        users: [],
        itemsToFilter: undefined,
        importPurchaseDialog: false,
        closedPurchases: [],
        selectedClosedPurchase: undefined,
        statusColors: Object.freeze({
            inProgress: {
                text: 'inProgress',
                color: 'primary',
            },
            onHold: {
                text: 'onHold',
                color: 'green',
            },
            pricing: {
                text: 'pricing',
                color: '#3293e6',
            },
            preApproval: {
                text: 'preApproval',
                color: '#FF9800',
            },
            approval: {
                text: 'approval',
                color: '#27BC4F',
            },
            purchasing: {
                text: 'purchasing',
                color: 'primary',
            },
            inTransit: {
                text: 'inTransit',
                color: '#ff7800',
            },
            received: {
                text: 'received',
                color: '#00a135',
            },
            closed: {
                text: 'closed',
                color: '',
            },
            rejected: {
                text: 'rejected',
                color: 'red',
            },
        }),
    }),
    computed: {
        ...mapState(['notificationResource']),
        filterRequests() {
            this.openResource()
            let conditions = []

            if (this.orderToFilter) {
                conditions.push(this.filterForOrder)
            }
            if (this.statusToFilter) {
                conditions.push(this.filterForStatus)
            }
            if (this.projectToFilter) {
                conditions.push(this.filterForProject)
            }
            if (this.itemsToFilter) {
                conditions.push(this.filterForItems)
            }

            if (this.deliveryParamToFilter) {
                conditions.push(this.filterForDeliveryAt)
            }

            if (this.idToFilter) {
                conditions.push(this.filterForId)
            }

            if (this.dateToFilter) {
                conditions.push(this.filterForDate)
            }

            if (this.dueDateToFilter) {
                conditions.push(this.filterForDueDate)
            }

            if (this.locationToFilter) {
                conditions.push(this.filterForLocation)
            }

            if (this.notesToFilter) {
                conditions.push(this.filterForNotes)
            }

            if (this.usersToFilter) {
                conditions.push(this.filterForUsers)
            }

            if (conditions.length > 0) {
                return this.requests.filter(request => {
                    return conditions.every(condition => {
                        return condition(request)
                    })
                })
            }

            this.excludeClosedPurchases()

            this.sort()
            return this.requests
        },
        getReceived() {
            return () => {
                const items = this.selectedClosedPurchase.items || []
                let received = []

                items.forEach(item => {
                    if (item.entries) {
                        const code = item.code
                        let quantityReceived = item.entries.reduce(
                            (sum, entry) => sum + entry.quantity,
                            0
                        )
                        received.push({ code, quantityReceived })
                    }
                })

                return id => {
                    const item = received.find(obj => obj.code === id)
                    return item ? item.quantityReceived : 0
                }
            }
        },
        itemsSelected() {
            let items = 0
            if (this.selectedClosedPurchase) {
                this.selectedClosedPurchase.items.forEach(item => {
                    if (item.isImportation) {
                        items++
                    }
                })
            }
            return items
        },
    },
    watch: {
        notificationResource: function(resource) {
            if (resource) {
                this.resourceId = resource
                this.openResource()
                this.setNotificationResource(undefined)
            }
        },
    },
    async created() {
        try {
            this.loading = true
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    async mounted() {
        try {
            this.resourceId = this.$route.query.resourceId
            this.loading = true

            let PurchasesQuery = firestore
                .collection('companies')
                .doc(this.companyId)
                .collection('purchases')
                .where('status', 'in', ['received', 'inTransit', 'closed'])
                .where('importation', '==', true)

            this.listener = await PurchasesQuery.onSnapshot(
                async docSnapshot => {
                    docSnapshot.docChanges().forEach(async change => {
                        const request = Object.assign(change.doc.data(), {
                            id: change.doc.id,
                        })
                        if (
                            !('comexFinished' in request) ||
                            request.comexFinished === false
                        ) {
                            if (request.items) {
                                request.items = request.items.filter(item => {
                                    if (item.isImportation !== true) {
                                        return false
                                    }
                                    request.itemsLabel = request.itemsLabel
                                        ? request.itemsLabel +
                                          `${item.description} ${
                                              item.purchaseOrder
                                                  ? '(' +
                                                    item.purchaseOrder
                                                        .orderNumber +
                                                    ')'
                                                  : ''
                                          }`
                                        : `${item.description} ${
                                              item.purchaseOrder
                                                  ? '(' +
                                                    item.purchaseOrder
                                                        .orderNumber +
                                                    ')'
                                                  : ''
                                          }`

                                    return true
                                })
                            }
                            if (change.type === 'added') {
                                request.dueDate = this.formatDate(
                                    request.dueDate.seconds
                                )
                                this.requests.push(request)
                            }
                            if (change.type === 'modified') {
                                request.dueDate = this.formatDate(
                                    request.dueDate.seconds
                                )
                                const index = this.requests.findIndex(
                                    r => r.id == request.id
                                )
                                if (index >= 0) {
                                    this.requests.splice(index, 1, request)
                                }
                            }
                            if (change.type === 'removed') {
                                const index = this.requests.findIndex(
                                    r => r.id == request.id
                                )
                                if (index >= 0) {
                                    this.requests.splice(index, 1)
                                }
                            }
                        }
                    })
                }
            )
            this.closedPurchases = await this.getClosedPurchases()
            this.excludeClosedPurchases()

            // get and limit the titles of the closed purchases.
            Object.values(this.closedPurchases).forEach(item => {
                const itemsText = item.items
                    .map(element => {
                        return `${element.description} (${element.quantity})${
                            element.purchaseOrder
                                ? ' (' + element.purchaseOrder.orderNumber + ')'
                                : ''
                        }`
                    })
                    .join(', ')

                let fullText = `${item.number} | ${itemsText}`
                item.displayText = fullText
            })
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    beforeDestroy() {
        this.listener()
        this.listener = null
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        ...mapActions(['setNotificationResource']),
        ...mapGetters(['getNotificationResource']),
        formatNumber(number) {
            return new Intl.NumberFormat('de-DE').format(
                Math.ceil(number != undefined ? number : 0)
            )
        },
        filterForId(item) {
            return item.number.toString().includes(this.idToFilter)
        },
        formatDate(seconds) {
            return moment.unix(seconds).format('YYYY-MM-DD')
        },
        closePurchase() {
            this.alertMessage = 'Purchase was successfully Closed'
            this.activateAlert = true
            this.closeRequestForm()
        },
        filterForDeliveryAt(item) {
            return (
                item.deliveryAt &&
                item.deliveryAt
                    .toLowerCase()
                    .includes(this.deliveryParamToFilter.toLowerCase())
            )
        },
        filterForStatus(item) {
            return (
                item.status &&
                item.status
                    .toLowerCase()
                    .includes(
                        this.statusToFilter.replaceAll(' ', '').toLowerCase()
                    )
            )
        },
        filterForProject(item) {
            return (
                item.project &&
                item.project.name
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase())
            )
        },
        filterForItems(item) {
            return (
                item.itemsLabel &&
                item.itemsLabel
                    .toLowerCase()
                    .includes(this.itemsToFilter.toLowerCase())
            )
        },
        filterForOrder(item) {
            return (
                item.order &&
                item.order
                    .toLowerCase()
                    .includes(this.orderToFilter.toLowerCase())
            )
        },
        filterForDate(item) {
            const date = this.formatDate(
                item.createdOn.seconds
                    ? item.createdOn.seconds
                    : item.createdOn._seconds
            )
            return date.includes(this.dateToFilter)
        },
        filterForDueDate(item) {
            return item.dueDate.includes(this.dueDateToFilter)
        },
        filterForNotes(item) {
            return (
                item.notes &&
                item.notes
                    .toLowerCase()
                    .includes(this.notesToFilter.toLowerCase())
            )
        },
        filterForLocation(item) {
            return (
                item.deliveryLocation &&
                item.deliveryLocation
                    .toLowerCase()
                    .includes(this.locationToFilter.toLowerCase())
            )
        },
        filterForUsers(item) {
            return (
                (item.createdBy?.name &&
                    item.createdBy?.name
                        .toLowerCase()
                        .includes(this.usersToFilter.toLowerCase())) ||
                (item.projectManager.name &&
                    item.projectManager.name
                        .toLowerCase()
                        .includes(this.usersToFilter.toLowerCase())) ||
                (item.buyer &&
                this.users.find(user => user.id == item.buyer) != undefined
                    ? this.users
                          .find(user => user.id == item.buyer)
                          .name.toLowerCase()
                          .includes(this.usersToFilter.toLowerCase())
                    : false)
            )
        },
        openRequest(request) {
            this.selectedRequest = { ...request }
            this.originalRequest = { ...request }
            this.openForm = true
        },
        close() {
            this.openForm = false
        },
        closeRequestForm() {
            this.openForm = false
            this.selectedRequest = {}
            this.originalRequest = {}
        },
        replaceRequest() {},
        openResource() {
            if (this.resourceId) {
                const resource = this.requests.find(
                    r => r.id == this.resourceId
                )
                if (resource) {
                    this.resourceId = undefined
                    this.openRequest(resource)
                }
            }
        },
        getUser(id) {
            const user = this.users.find(u => u.id == id)
            if (user) {
                return user.name
            }
            return null
        },
        sort() {
            this.requests.sort(
                (a, b) => b.createdOn.seconds - a.createdOn.seconds
            )
        },
        calculateTotal(request) {
            let calculatedTotal = 0
            request.items = request.items ? request.items : []
            request.items.forEach(i => {
                i.quotes = i.quotes ? i.quotes : []
                i.quotes.forEach(q => {
                    if (q.able) {
                        calculatedTotal += i.quantity * q.price
                    }
                })
            })
            return calculatedTotal
        },
        deleteRequest(id) {
            const indexRequest = this.requests.findIndex(obj => obj.id === id)
            if (indexRequest > -1) {
                this.requests.splice(indexRequest, 1)
            }
        },
        openImportPurchase() {
            this.importPurchaseDialog = true
        },
        closeImportPurchase() {
            this.importPurchaseDialog = false
            this.selectedClosedPurchase = undefined
        },
        async getClosedPurchases() {
            let closedPurchases = await API.getPurchases({
                status: 'closed',
            })

            // filter purchases
            let filteredPurchases = []
            if (closedPurchases.length > 0) {
                Object.values(closedPurchases).forEach(purchase => {
                    if (purchase.status == 'closed') {
                        if (
                            purchase.comex?.length === 0 ||
                            !purchase.importation
                        ) {
                            filteredPurchases.push(purchase)
                        }
                    }
                })
            }
            return filteredPurchases
        },
        getSupplier(item) {
            const selectedQuote = item.quotes?.find(quote => quote.able)
            return selectedQuote ? selectedQuote.supplier?.name : undefined
        },
        validateItemQty(item) {
            let entryQty = 0
            if (item.entries && item.entries.length > 0) {
                entryQty = item.entries.reduce(
                    (accumulator, entry) => entry.quantity + accumulator,
                    0
                )
            }
            if (entryQty >= item.quantity) {
                return 'mt-3 font-weight-medium green--text'
            } else if (entryQty == 0) {
                return 'mt-3 font-weight-medium red--text'
            } else {
                return 'mt-3 font-weight-medium orange--text'
            }
        },
        numberFormat(amount) {
            const value = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 2,
            }).format(amount)
            return value
        },
        excludeClosedPurchases() {
            if (this.closedPurchases.length > 0 && this.requests.length > 0) {
                this.closedPurchases = this.closedPurchases.filter(
                    closedPurchase =>
                        !this.requests.some(
                            requestPurchase =>
                                requestPurchase.number === closedPurchase.number
                        )
                )
            }
        },
        async saveImport() {
            try {
                this.loading = true
                if (this.selectedClosedPurchase) {
                    this.selectedClosedPurchase.importation = true
                    await API.createClosedPurchase(
                        this.selectedClosedPurchase.id,
                        this.selectedClosedPurchase
                    )
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.closeImportPurchase()
            }
        },
    },
}
</script>

<style>
.v-data-table {
    border: 1px solid #eeeeee;
}
thead {
    background: #eeeeee;
}
.v-input .v-label {
    font-size: 12px;
}
.v-data-table-header th {
    background-color: #eeeeee !important;
}
.v-data-table__wrapper {
    max-height: 72vh;
    overflow-y: auto;
}
.v-data-table__row {
    height: 64px;
}
.v-data-table__wrapper thead tr {
    position: sticky;
    top: 0;
    z-index: 5;
}
.header-container {
    background-color: #eeeeee;
}
</style>
